.navbar {
    position: sticky;
    top: 0px;
    height: 70px;
    background-color: black;
    opacity: 0.75;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px 0px 30px;
    margin-bottom: -70px;
    z-index: 5;
}

.navbar_logo {
    color: white;
    font-family: "Montecarlo", cursive;
    font-size: 2.5rem;
}

.navbar_list{
    display: flex;
    list-style: none;
}

.navbar_item {
    white-space: nowrap;
}

.navbar_link {
    font-size: 1rem;
    text-decoration: none;
    color: white;
    text-align: center;
    padding: 10px;
    margin: 0px 5px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: transparent;
    transition: 250ms;
} 

.navbar_link:hover {
    border-color: white;
    transition: 250ms;
}

.navbar_menu {
    color: white;
    cursor: pointer;
    display: none;
}

@media screen and (max-width:800px) {
    .navbar_list{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        position: absolute;
        top: 70px;
        right: -100%;
        width: 100%;
        height: 250px;
        transition: all 0.5s;
    }

    .navbar_list--active {
        right: 0;
    }

    .navbar_item{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        flex: 1;
    }

    .navbar_link {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 0px;
        border-width: 0px;
        background-color: black;
        opacity: 0.75;
    }

    .navbar_link:hover{
        opacity: 0.9;
    }
    .navbar_menu {
        display: block;
    }
}