.hero {
    position: relative;
    width: 100%;
    height: 100vh;
}

.hero_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.hero_title{
    position: absolute;
    top: 30%;
    left: 8%;
    color: white;
    font-size: 4rem;
    padding: 15px;
}